import { useEffect, useRef, useState } from 'react';
import { useSection } from '../../../stores/useSection';
// import { useFace } from '../../../stores/useFace';
import { editable as e, SheetProvider } from '@theatre/r3f';
import { useSounds } from '../../../stores/useSounds';
import { useKTX2, useTexture } from '@react-three/drei';

export function Coin() {
  /*
   * properties
   */

  const [color1, normal1, roughMetal1, color2, normal2, roughMetal2] = useKTX2([
    'textures/coiin_export_coin_back_BaseColor.ktx2',
    'textures/coiin_export_coin_back_Normal.ktx2',
    'textures/coiin_export_coin_back_RoughnessMetallic.ktx2',
    'textures/coiin_export_coin_front_BaseColor.ktx2',
    'textures/coiin_export_coin_front_Normal.ktx2',
    'textures/coiin_export_coin_front_RoughnessMetallic.ktx2',
  ]);

  // const startMoving = useFace((state) => state.startMoving);
  // const stopMoving = useFace((state) => state.stopMoving);
  const playSound = useSounds((state) => state.playSound);

  const section = useSection((state) => state.section);
  const setSection = useSection((state) => state.setSection);

  const [shown, setShown] = useState(false);

  /*
   * hooks
   */

  useEffect(() => {
    if (section === 'coin' && !shown) {
      console.log('Coin :: show');

      playSound('coinShow');
      setTimeout(playSwoosh, 2000);

      setShown(true);
      setTimeout(startGame, 4500);
    } else if (shown) {
      setShown(false);
      console.log('Coin :: hide');
    }
  }, [section]);

  const playSwoosh = () => {
    playSound('swoosh');
  };

  const startGame = () => {
    // console.log('Coin :: startGame');
    setSection('game');
  };

  /*
   * visuals
   */

  return (
    <>
      {/* {shown && ( */}
      <>
        <e.group theatreKey="coin">
          <mesh rotation={[1.57, 0, 3.14]} position={[0, -0.08, 0]}>
            <circleGeometry args={[2, 64]} />
            <meshStandardMaterial
              // map={color}
              map={color1}
              normalMap={normal1}
              // normalScale={[2, 2]}
              roughnessMap={roughMetal1}
              roughness={1}
              metalnessMap={roughMetal1}
              metalness={0.5}
              // envMapIntensity={1}
            />
          </mesh>
          <mesh rotation={[-1.57, 0, 0]} position={[0, 0.08, 0]}>
            <circleGeometry args={[2, 64]} />
            <meshStandardMaterial
              map={color2}
              normalMap={normal2}
              // normalScale={[2, 2]}
              roughnessMap={roughMetal2}
              roughness={1}
              metalnessMap={roughMetal2}
              metalness={0.5}
              // envMapIntensity={1}
            />
          </mesh>
          <mesh>
            <cylinderGeometry args={[2, 2, 0.16, 64, 1, true]} />
            <meshStandardMaterial color="#8e6b46" metalness={0.9} roughness={0.2} envMapIntensity={3} />
          </mesh>
        </e.group>
      </>
      {/* )} */}
    </>
  );
}
