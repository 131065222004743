import { useEffect, useMemo, useRef, useState } from 'react';
import gsap from 'gsap';
import { useFrame } from '@react-three/fiber';
import { Timeline } from 'gsap/gsap-core';
import { useSparks } from '../../../stores/useSparks';

export function SparkLight() {
  /*
   * properties
   */
  const shown = useSparks((state) => state.shown);

  const light = useRef();

  const intensity = useRef(0);

  /*
   * hooks
   */

  useEffect(() => {
    if (shown) {
      show();
    }
  }, [shown]);

  const show = () => {
    gsap.killTweensOf(intensity);
    const tl = new Timeline();
    tl.to(intensity, { current: 20, duration: 0.1, ease: 'power2.inOut' });
    tl.to(intensity, { current: 0, duration: 0.1, ease: 'power2.inOut' });
    tl.to(intensity, { current: 100, duration: 0.1, ease: 'power2.inOut' });
    tl.to(intensity, { current: 0, duration: 0.9, ease: 'sine.out' });
  };

  useFrame(() => {
    if (shown) {
      light.current.intensity = intensity.current;
    }
  });

  /*
   * visuals
   */

  return (
    <>
      <pointLight visible={shown} position={[-1, 1, 2]} ref={light} color="#5cc4ff" distance={30} decay={0.7} />
    </>
  );
}
