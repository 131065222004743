import { shaderMaterial, useTexture } from '@react-three/drei';
import { useFrame, extend } from '@react-three/fiber';
import { useEffect, useMemo, useRef, useState } from 'react';
import vertexShader from './shaders/vertex.glsl?raw';
import fragmentShader from './shaders/fragment.glsl?raw';
import * as THREE from 'three';
import { useSparks } from '../../../stores/useSparks';

const amount = 20;
const width = 1;
const height = 1;
const depth = 1;

const Sparkmaterial = shaderMaterial(
  {
    uTime: 0,
    uColor: new THREE.Color('#addbff'),
    uTexture: null,
    uResolutionFactor: 150,
  },
  vertexShader,
  fragmentShader
);

extend({ Sparkmaterial });

export function Particles() {
  /*
   * properties
   */
  const shown = useSparks((state) => state.shown);

  const sparkMaterial = useRef();
  const mesh = useRef();

  let startTime;

  const texture = useTexture('/textures/spark.png');
  // const texture = useTexture('/textures/blurredPoint-min.png');

  const points = useMemo(() => {
    const p = new Array(amount * 3);
    for (let i = 0; i < amount; i++) {
      p[i * 3 + 0] = -width * 0.5 + Math.random() * width;
      p[i * 3 + 1] = -height * 0.5 + Math.random() * height;
      p[i * 3 + 2] = -depth * 0.5 + Math.random() * depth;
    }

    return new Float32Array(p);
  }, [amount]);

  const angle = useMemo(() => {
    const s = new Array(amount);
    for (let i = 0; i < amount; i++) {
      s[i] = Math.PI * 2 * Math.random();
    }

    return new Float32Array(s);
  }, [amount]);

  const speed = useMemo(() => {
    const s = new Array(amount);
    for (let i = 0; i < amount; i++) {
      s[i] = 1 + Math.random() * 1.5;
    }

    return new Float32Array(s);
  }, [amount]);

  /*
   * hooks
   */

  useEffect(() => {
    window.addEventListener('resize', resizeHandler);
    resizeHandler();

    return () => {
      window.removeEventListener('resize', resizeHandler);
    };
  }, []);

  const resizeHandler = () => {
    // console.log('Particles :: resizeHandler');
    sparkMaterial.current.uniforms.uResolutionFactor.value = Math.min(window.innerWidth, window.innerHeight) * 0.15;
  };

  useFrame((state) => {
    if (sparkMaterial.current && shown) {
      const time = state.clock.elapsedTime;
      if (!startTime) {
        startTime = time;
      }
      sparkMaterial.current.uniforms.uTime.value = time - startTime;
    }
  });

  /*
   * visuals
   */

  return (
    <>
      {/* <mesh>
        <boxGeometry args={[0.1, 0.1, 0.1]} />
        <meshStandardMaterial color="green" wireframe />
      </mesh> */}

      <points ref={mesh} visible={shown}>
        <bufferGeometry>
          <bufferAttribute attach={'attributes-position'} args={[points, 3, false]} />
          <bufferAttribute attach={'attributes-aAngle'} args={[angle, 1, false]} />
          <bufferAttribute attach={'attributes-aSpeed'} args={[speed, 1, false]} />
        </bufferGeometry>
        <sparkmaterial ref={sparkMaterial} uTexture={texture} transparent={true} depthWrite={false} />
      </points>
    </>
  );
}
