import { useControls } from 'leva';
import { Perf } from 'r3f-perf';
import * as THREE from 'three';

export function Staging() {
  /*
   * properties
   */

  const color = new THREE.Color('#000000');

  const config = useControls('staging', {
    showPerf: { value: false, label: 'Perf panel' },
  });

  // const config = { showPerf: false };

  /*
   * hooks
   */

  /*
   * visuals
   */

  return (
    <>
      <fog attach="fog" color={color} near={12} far={50} />
      <color attach="background" args={[color]} />
      {config.showPerf && <Perf position="top-left" />}
    </>
  );
}
