import style from './Message.module.css';
import { useEffect, useRef, useState } from 'react';
import { useSection } from '../../../stores/useSection';
import { editable as e, SheetProvider } from '@theatre/r3f';
import * as THREE from 'three';
import { useSounds } from '../../../stores/useSounds';
import { Html, useKTX2, useTexture } from '@react-three/drei';
import gsap from 'gsap';

export function Message() {
  /*
   * properties
   */

  const wrapper = useRef();
  const body = useRef();
  const numbers = useRef();

  // const texture = useTexture('textures/message-min.png');
  const textureFront = useTexture('textures/message_front-min.png');
  const textureBack = useTexture('textures/message_back-min.png');

  const section = useSection((state) => state.section);
  const playSound = useSounds((state) => state.playSound);

  const [shown, setShown] = useState(false);

  /*
   * hooks
   */

  useEffect(() => {
    if (section === 'message' && !shown) {
      console.log('Message :: show');
      playSound('switchSection');
      setShown(true);

      setTimeout(() => {
        showParagraph(body.current);
        showParagraph(numbers.current);
      }, 8500);
    }
  }, [section]);

  const showParagraph = (paragraph) => {
    const children = Array.from(paragraph.children);

    for (const child of children) {
      gsap.to(child, { delay: Math.random() * 1, duration: 1, opacity: 1, ease: 'sine.in' });
    }
  };

  /*
   * visuals
   */

  return (
    <>
      {/* {shown && ( */}
      <>
        {/* <e.mesh theatreKey="envelope">
            <planeGeometry args={[1.5, 1]} />
            <meshStandardMaterial map={textureFront} />
          </e.mesh> */}

        <e.group theatreKey="envelope">
          <mesh rotation={[3.14, 0, 0]}>
            <planeGeometry args={[1.5, 0.927]} />
            <meshStandardMaterial map={textureFront} transparent />
          </mesh>
          <mesh>
            <planeGeometry args={[1.5, 0.927]} />
            <meshStandardMaterial map={textureBack} transparent />
          </mesh>
        </e.group>
        <Html position={[0, -6.85, 3.18]} transform center distanceFactor={2}>
          <div ref={wrapper} className={style.wrapper}>
            <p ref={body} className={style.body}>
              {'In the quest for love wacht for signs in unexpected laughter. Your soulmate may be cloaked in jest. Find joy, and you find the key to hearts.'
                .split('')
                .map((value, index) => (
                  <span key={index}>{value}</span>
                ))}
            </p>
            <p ref={numbers} className={style.number}>
              {'10, 12, 13, 3, 2, 1'.split('').map((value, index) => (
                <span key={index}>{value}</span>
              ))}
            </p>
          </div>
        </Html>
      </>
      {/* )} */}
    </>
  );
}
