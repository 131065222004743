import style from './Coin.module.css';

import { useProgress } from '@react-three/drei';
import { useEffect, useRef, useState } from 'react';
import gsap from 'gsap';

export function Coin({ shown, finished, doRotate }) {
  /*
   * properties
   */

  const intervalId = useRef();

  const container = useRef();
  const coin = useRef();
  const shine_front = useRef();
  const shine_back = useRef();

  const rotate = useRef(true);
  const rotation = useRef(false);
  const scale = useRef(0);

  const prevDate = useRef(0);

  /*
   * hooks
   */

  useEffect(() => {
    resizeHandler();
    window.addEventListener('resize', resizeHandler);

    return () => {
      window.removeEventListener('resize', resizeHandler);
    };
  }, []);

  const resizeHandler = () => {
    const scale = Math.min(window.innerHeight, window.innerWidth) * 0.00075;
    container.current.style.transform = `translate(-50%, -50%) scale(${scale})`;
  };

  useEffect(() => {
    if (doRotate === true) {
      rotate.current = true;
      // gsap.to(scale, { current: 1, duration: 0.5, ease: 'sine.out' });
    } else if (doRotate === false) {
      rotate.current = false;

      const duration = scale.current > 0.9 ? 1.5 : 0.5;

      const endRotation = rotation.current > 180 ? 540 : 360;
      gsap.to(rotation, { current: endRotation, duration, ease: 'sine.out' });
    }
  }, [doRotate]);

  useEffect(() => {
    if (!finished) {
      gsap.to(scale, { current: 1, duration: 0.5, ease: 'sine.out' });
    } else {
      gsap.to(scale, { current: 0.43, duration: 1, ease: 'sine.inOut' });
    }
  }, [finished]);

  useEffect(() => {
    if (!shown) {
      gsap.to(scale, { current: 0, duration: 0.5, onComplete: stopHide, ease: 'back.in' });
    }
  }, [shown]);

  const stopHide = () => {
    // console.log('Coin :: stopHide');
    coin.current.style.visibility = 'hidden';
    clearInterval(intervalId.current);
  };

  useEffect(() => {
    intervalId.current = setInterval(loop, 1000 / 60);

    return () => {
      clearInterval(intervalId.current);
    };
  }, []);

  const loop = () => {
    const date = new Date();
    const now = date.getTime();

    if (!prevDate.current) {
      prevDate.current = now;
    }

    if (rotate.current) {
      rotation.current += (prevDate.current - now) * -0.3;
      rotation.current = rotation.current % 360;
    }

    prevDate.current = now;

    coin.current.style.transform = `perspective(1000px) rotateY(${rotation.current}deg) scale3d(${scale.current}, ${scale.current}, ${scale.current})`;
    const shinePos = Math.round(-100 + 900 * (rotation.current / 180));

    shine_front.current.style.transform = `translateY(${shinePos}px) rotate(-40deg)`;
    shine_back.current.style.transform = `translateY(${shinePos}px) rotate(-40deg)`;
  };

  /*
   * visuals
   */

  return (
    <>
      <div className={style.coin_container} ref={container}>
        <div className={style.coin} ref={coin}>
          <div className={style.coin__front}>
            <div className={style.coin__front__shine} ref={shine_front} />
          </div>
          <div className={style.side}>
            {[...Array(48)].map((value, index) => (
              <div
                key={index}
                className={style.circle}
                style={{ transform: `rotateY(90deg) rotateX(${(180 / 48) * (index + 1)}deg)` }}
              ></div>
            ))}
          </div>
          <div className={style.coin__back}>
            <div className={style.coin__back__shine} ref={shine_back} />
          </div>
          {/* <div className={style.coin__shadow}></div> */}
        </div>
      </div>
    </>
  );
}
