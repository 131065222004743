import { useEffect, useRef, useState } from 'react';
import { OrbitControls, PerspectiveCamera } from '@react-three/drei';
import { MathUtils } from 'three';
import { editable as e, SheetProvider } from '@theatre/r3f';
import gsap from 'gsap';

import { useFrame } from '@react-three/fiber';
import { useMouse } from '../../stores/useMouse';
import { useSection } from '../../stores/useSection';

export function CameraManager() {
  /*
   * properties
   */

  const camera = useRef();
  // const wrapper = useRef();
  const ratio = 1 / 1.25;

  // const section = useSection((state) => state.section);

  // const config = useControls('camera', {
  //   positionX: { value: 0, min: -10, max: 10, step: 0.1 },
  //   positionY: { value: 7, min: -10, max: 10, step: 0.1 },
  //   positionZ: { value: 0, min: -10, max: 10, step: 0.1 },
  //   rotationX: { value: 4.712, min: -10, max: 10, step: 0.1 },
  //   rotationY: { value: 0, min: -4, max: 4, step: 0.1 },
  //   rotationZ: { value: 6.283, min: -4, max: 4, step: 0.1 },
  // });

  // state properties

  /*
   * hooks
   */

  // set camera FOV after resizing
  useEffect(() => {
    window.addEventListener('resize', resizeHandler);
    resizeHandler();
  }, []);

  const resizeHandler = (e) => {
    const fov = 50;
    const cam = camera.current;

    if (cam.aspect > ratio) {
      //window too wide
      cam.fov = fov;
    } else {
      // window too narrow
      const cameraHeight = Math.tan(MathUtils.degToRad(fov * 0.5));
      const rat = cam.aspect / ratio;
      cam.fov = MathUtils.radToDeg(Math.atan(cameraHeight / rat)) * 2;
    }
  };

  //move camera on mouse move
  useFrame(() => {
    const { slowPctX, slowPctY, inited } = useMouse.getState();

    if (inited) {
      camera.current.position.x = slowPctX * 0.4;
      camera.current.position.y = slowPctY * -0.15;

      camera.current.rotation.y = slowPctX * 0.04;
      camera.current.rotation.x = slowPctY * 0.015;
    }
  });

  /*
   * visuals
   */

  return (
    <>
      <e.group theatreKey="camera">
        <PerspectiveCamera ref={camera} makeDefault={true} near={0.1} far={100} position={[0, 0, 12]} />
      </e.group>

      {/* <PerspectiveCamera ref={camera} position={[0, 0, 30]} makeDefault={true} near={0.1} far={100} />
      <OrbitControls makeDefault dampingFactor={1} /> */}
    </>
  );
}
