import { Howl, Howler } from 'howler';
import { create } from 'zustand';

const sounds = {
  lights: new Howl({ src: ['/sounds/effects/neon_light_01.mp3'] }),
  switchSection: new Howl({ src: ['/sounds/effects/drum_build_crescendo.mp3'] }),
  crank: new Howl({ loop: true, src: ['/sounds/effects/0B_handle_rotate.mp3'] }),
  breath: new Howl({ src: ['/sounds/effects/breath.mp3'] }),
  click: new Howl({ src: ['/sounds/effects/click.mp3'] }),
  button: new Howl({ src: ['/sounds/effects/ding.mp3'] }),
  swoosh: new Howl({ src: ['/sounds/effects/magic_whoosh_sparkle.mp3'] }),
  gameover: new Howl({ src: ['/sounds/effects/oriental_glissando.mp3'] }),
  electricity: new Howl({ src: ['/sounds/effects/electricity_01.mp3'] }),

  speech: new Howl({ src: ['/sounds/speech/speech.mp3'] }),

  coinShow: new Howl({ src: ['/sounds/effects/cha_ching.mp3'] }),
  coinRolling: new Howl({ src: ['/sounds/effects/coin_rolling.mp3'] }),
  coinHit: new Howl({ src: ['/sounds/effects/coin_hit.mp3'] }),
  // coinEnterMouth: new Howl({ src: ['/sounds/effects/coin_enter_mouth.mp3'] }),

  music: new Howl({ loop: true, html5: true, volume: 0, src: ['/sounds/music/loop_main.mp3'] }),
};

export const useSounds = create((set) => ({
  playSound: (name) => {
    const sound = sounds[name];

    //TEMP UITGEZET
    sound.play();

    return sound;
  },
}));
