import { create } from 'zustand';

export const useSection = create((set, get) => ({
  section: '',
  // divider: false,

  setSection: (section) => set({ section }),
  // showDivider: () => set({ divider: true }),
  // hideDivider: () => set({ divider: false }),
}));
