import React, { Suspense, useState } from 'react';

import { MouseController } from './controllers/MouseController';
import { App3D } from './app3D/App3D';

import { Leva } from 'leva';
import { MainLoader } from './app2D/MainLoader';

/*
 * THEATRE studio start, ENABLE for studio
 */

// import studio from '@theatre/studio';
// import extension from '@theatre/r3f/dist/extension';

// if (import.meta.env.DEV) {
//   studio.initialize();
//   studio.extend(extension);
// }

/*
 * THEATRE studio end
 */

export function App() {
  /*
   * properties
   */

  const [show3D, setShow3D] = useState(false);

  /*
   * hooks
   */

  const startLoading = () => {
    console.log('App :: startLoading');
    setShow3D(true);
  };

  /*
   * visuals
   */

  return (
    <>
      <MouseController />
      <MainLoader startLoading={startLoading} />
      {show3D && (
        <Suspense>
          <App3D />
        </Suspense>
      )}
    </>
  );
}
