import React, { useRef } from 'react';
import { useGLTF } from '@react-three/drei';

export function Plug() {
  const { nodes, materials } = useGLTF('/plug/electrical_cord.glb');

  return (
    <group scale={[0.1, 0.1, 0.1]} dispose={null}>
      <group position={[8.047, -0.543, 16.676]} rotation={[-0.062, 0.348, 0.018]}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.prongs_remesh.geometry}
          material={materials.metal}
          position={[-1.03, 0.383, 9.101]}
          rotation={[0.021, 1.033, 3.055]}
          scale={[0.9, 1, 1]}
        />
      </group>
      <group position={[8.047, -0.543, 16.676]} rotation={[-0.062, 0.348, 0.018]}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes['plug-housing_remesh'].geometry}
          material={materials.cord}
          position={[-1.03, 0.383, 9.101]}
          rotation={[0.021, 1.033, 3.055]}
        />
      </group>
      <group position={[8.047, -0.543, 16.676]} rotation={[-0.062, 0.348, 0.018]}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes['plug-base_remesh'].geometry}
          material={materials.cord}
          position={[-1.03, 0.383, 9.101]}
          rotation={[0.021, 1.033, 3.055]}
        />
      </group>
      <group position={[11.688, 0, 0]}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.cord_remesh.geometry}
          material={materials.cord}
          position={[-5.409, 2.022, 8.531]}
          rotation={[1.938, 0.434, 1.042]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cap_1_remesh.geometry}
          material={materials.cord}
          position={[-1.374, 0.243, 24.538]}
          rotation={[-0.064, -0.31, -0.421]}
        />
      </group>
    </group>
  );
}
