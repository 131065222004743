import { useEffect, useMemo, useRef, useState } from 'react';
import { Plug } from './Plug';
import { SparkLight } from './SparkLight';
import { Particles } from './Particles';
import { editable as e, SheetProvider } from '@theatre/r3f';
import { useSparks } from '../../../stores/useSparks';
import { useSounds } from '../../../stores/useSounds';

export function Sparks() {
  /*
   * properties
   */

  const setShown = useSparks((state) => state.setShown);
  const [theatreObject, setTheatreObject] = useState(null);
  const playSound = useSounds((state) => state.playSound);

  /*
   * hooks
   */

  useEffect(
    () => {
      // if `theatreObject` is `null`, we don't need to do anything
      if (!theatreObject) return;

      const unsubscribe = theatreObject.onValuesChange((data) => {
        // Apply the new offset to our THREE.js object
        // console.log('Sparks, data.show: ' + data.show);

        if (data.show) {
          // console.log('Show Sparks');
          setShown(true);
          playSound('electricity');
          // playSound('coinShow');
        } else if (!data.show) {
          // console.log('Hide Sparks');
          setShown(false);
        }
      });
      // unsubscribe from the listener when the component unmounts
      return unsubscribe;
    },
    // We only want to run this `useEffect()` when `theatreObject` changes
    [theatreObject]
  );

  /*
   * visuals
   */

  return (
    <>
      <e.group
        position={[-1, -11.6, 5]}
        scale={3}
        rotation={[0.015, 0, 0]}
        theatreKey="sparks"
        additionalProps={{
          show: false,
        }}
        objRef={setTheatreObject}
      >
        <Plug />
        <group position={[0.99, 0.05, 2.7]}>
          <SparkLight />
          <Particles />
        </group>
      </e.group>
    </>
  );
}
