import style from './MainLoader.module.css';

import { useProgress } from '@react-three/drei';
import { useEffect, useRef, useState } from 'react';
import { Coin } from './Coin';
import { useSection } from '../stores/useSection';
import { MainButton } from './MainButton';
import gsap from 'gsap';
import { useSounds } from '../stores/useSounds';
import { Curtains } from './Curtains';

export function MainLoader({ startLoading }) {
  /*
   * properties
   */

  // const wrapper = useRef();

  const setSection = useSection((state) => state.setSection);
  const playSound = useSounds((state) => state.playSound);

  const clicked = useRef(false);
  const content = useRef();

  const { active, progress } = useProgress();
  let [finished, setFinished] = useState(false);
  let [rotateCoin, setRotateCoin] = useState(true);
  let [shown, setShown] = useState(true);
  let [showCoin, setShowCoin] = useState(true);

  /*
   * hooks
   */

  useEffect(() => {
    startLoading();
  }, []);

  useEffect(() => {
    if (progress >= 100) {
      setRotateCoin(false);
      setFinished(true);
    }
  }, [active, progress]);

  const clickHandler = () => {
    if (!clicked.current) {
      clicked.current = true;

      setShowCoin(false);

      playSound('click');
      const sound = playSound('music');
      setTimeout(() => {
        sound.fade(0, 1, 1000);
      }, 500);

      gsap.to(content.current, { duration: 0.6, opacity: 0, scale: 1.1, onComplete: hideHandler, ease: 'power4.in' });
    }
  };

  const hideHandler = () => {
    setSection('talk');
    setShown(false);
  };

  const hoverHandler = () => {
    // console.log('hoverHandler ***');
    setRotateCoin(true);
  };

  const leaveHandler = () => {
    // console.log('leaveHandler ***');
    setRotateCoin(false);
  };

  // useEffect(() => {
  //   console.log('rotateCoin: ' + rotateCoin);
  // }, [rotateCoin]);

  /*
   * visuals
   */

  return (
    <>
      {shown && (
        <div className={style.wrapper}>
          <Curtains shown={!finished} />
          {/* <img className={style.example} src="/images/intro_example.png" /> */}
          <div ref={content} className={style.content}>
            <img
              className={style.header}
              src="/images/header-min.png"
              alt="Curious & Company presents the master of fortunes..."
            />
            <img className={style.title} src="/images/title-min.png" alt="Curio speaks" />
            <MainButton
              shown={finished}
              clickHandler={clickHandler}
              hoverHandler={hoverHandler}
              leaveHandler={leaveHandler}
            />
            <img className={style.left} src="/images/left-min.png" alt="Know your destiny" />
            <img className={style.right} src="/images/right-min.png" alt="Reveal your fortune" />
            <img className={style.footer} src="/images/footer-min.png" alt="Your destiny is loading" />
          </div>
          <Coin shown={showCoin} finished={finished} doRotate={rotateCoin} />
        </div>
      )}
    </>
  );
}
