import { CuboidCollider, CylinderCollider, RigidBody } from '@react-three/rapier';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useCoins } from '../../../stores/useCoins';
import { Coin } from './Coin';
import * as THREE from 'three';
import { useFrame } from '@react-three/fiber';
import { useRamp } from '../../../stores/useRamp';
import { useSounds } from '../../../stores/useSounds';
import { useSection } from '../../../stores/useSection';

export function Ramp() {
  /*
   * properties
   */

  const volume = useRef(0);
  const sound = useRef();

  const [isActive, setIsActive] = useState(false);

  const section = useSection((state) => state.section);
  const rotationX = useRamp((state) => state.rotationX);
  const rotationY = useRamp((state) => state.rotationY);
  const playSound = useSounds((state) => state.playSound);

  //
  /*
   * hooks
   */
  useEffect(() => {
    if (section === 'game' && !isActive) {
      // console.log('Game :: show');
      setIsActive(true);
      sound.current = playSound('crank');
    }
  }, [section]);

  useFrame((state, delta) => {
    volume.current *= 0.3;
    if (isActive) {
      sound.current.volume(volume.current);
    }
  });

  useEffect(() => {
    volume.current = 1;
  }, [rotationX, rotationY]);

  /*
   * visuals
   */

  return <></>;
}
