import { CylinderCollider, RigidBody } from '@react-three/rapier';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useCoins } from '../../../stores/useCoins';
import { useFrame } from '@react-three/fiber';
import * as THREE from 'three';
import { useRamp } from '../../../stores/useRamp';
import gsap from 'gsap';
import { useSounds } from '../../../stores/useSounds';

const geometry = new THREE.CylinderGeometry(0.14, 0.14, 0.04);
// const material = new THREE.MeshStandardMaterial({ color: '#ffdf05', metalness: 0.9, roughness: 0 });
const material = new THREE.MeshStandardMaterial({
  color: '#8e6b46',
  metalness: 0.95,
  roughness: 0.1,
  envMapIntensity: 3,
});

export function Coin({ index, endGame }) {
  /*
   * properties
   */

  const rigidBody = useRef();
  const container = useRef();
  const mesh = useRef();
  const [type, setType] = useState('dynamic');

  const introCoin = useRef();
  const group = useRef();

  const playSound = useSounds((state) => state.playSound);

  const coins = useCoins((state) => state.coins);
  const disablePhysics = useCoins((state) => state.disablePhysics);

  const [rotation, setRotation] = useState([0, 0, 0]);
  const [position, setPosition] = useState([0, 0, 0]);

  const [showIntro, setShowIntro] = useState(false);
  const [showCoin, setShowCoin] = useState(false);

  /*
   * hooks
   */

  useEffect(() => {
    if (!showIntro && !showCoin && useCoins.getState().coins > index) {
      const rotX = useRamp.getState().rotationX;
      const rotY = useRamp.getState().rotationY;
      let pos = [2.6, 3, 1.42]; //ramp position

      const radiusV = 2.08;
      const y = Math.sin(rotX) * radiusV;
      const radiusH = Math.cos(rotX) * radiusV;

      const x = Math.sin(rotY) * radiusH;
      const z = Math.cos(rotY) * radiusH;

      setPosition([pos[0] - x, pos[1] - 1.6 + y, pos[2] - z]);
      setRotation([0, rotY, 1.57]);

      setShowIntro(true);
    }
  }, [coins]);

  useEffect(() => {
    if (showIntro) {
      // console.log('introCoin', introCoin.current);

      playSound('coinRolling');

      const rotX = useRamp.getState().rotationX;
      const rotY = useRamp.getState().rotationY;

      group.current.rotation.reorder('ZYX');
      group.current.rotation.set(rotX, rotY, 0);

      const duration = 0.75;

      gsap.to(introCoin.current.position, { y: 0.2, duration, ease: 'power2.in' });
      gsap.to(introCoin.current.position, {
        z: -2.25,
        duration,
        ease: 'sine.in',
        onComplete: () => {
          setShowCoin(true);
          setShowIntro(false);
        },
      });
    }
  }, [showIntro]);

  useEffect(() => {
    if (showCoin) {
      const forceH = 0.01;
      const forceV = 0.0045;
      rigidBody.current.applyImpulse(
        { x: -Math.sin(rotation[1]) * forceH, y: forceV, z: -Math.cos(rotation[1]) * forceH },
        true
      );
    }
  }, [showCoin]);

  const onContactForce = (payload) => {
    // console.log('Coin contact');
    const force = payload.totalForce;
    const totalForce = Math.abs(force.x) + Math.abs(force.y) + Math.abs(force.z);

    if (payload.colliderObject.name === 'mouth') {
      endGame();
      console.log('*** Coin :: gameover');
      setType('fixed'); //nodig?

      // gsap.to(container.current.position, { z: mesh.current.position.z - 1, duration: 0.5, ease: 'power2.out' });
      gsap.to(mesh.current.scale, { x: 0, y: 0, z: 0, duration: 0.3, ease: 'power2.out' });

      disablePhysics();
    } else if (totalForce > 500) {
      playSound('coinHit');
    }
  };

  /*
   * visuals
   */

  return (
    <>
      {/* <group ref={container}> */}
        {showIntro && (
          <group ref={group} position={[2.73, 1.05, 1.45]}>
            <mesh
              ref={introCoin}
              rotation={[0, 0, 1.57]}
              position={[-0.05, 1.9, -0.3]} //START POSITTION
              // position={[-0.05, 0.33, -2.25]} //END POSITTION
              geometry={geometry}
              material={material}
              castShadow
              receiveShadow
            />
          </group>
        )}
        {showCoin && (
          <RigidBody
            ref={rigidBody}
            position={position}
            rotation={rotation}
            colliders={false}
            restitution={1}
            friction={0}
            type={type}
            onContactForce={onContactForce}
            name="coin"
          >
            <mesh ref={mesh} geometry={geometry} material={material} castShadow receiveShadow />
            <CylinderCollider args={[0.02, 0.14]} mass={2} name="coin" />
          </RigidBody>
        )}
      {/* </group> */}
    </>
  );
}
