import { CuboidCollider, CylinderCollider, MeshCollider, RigidBody } from '@react-three/rapier';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useCoins } from '../../../stores/useCoins';
import { Coin } from './Coin';
import * as THREE from 'three';
import { Ramp } from './Ramp';
import { Handle } from './Handle';
import { useFrame } from '@react-three/fiber';
import { MeshDiscardMaterial } from '@react-three/drei';
import { useFace } from '../../../stores/useFace';

export function Statue() {
  /*
   * properties
   */

  // const config = useControls('walls', {
  //   position: { value: { x: 0, y: -3.02, z: 0.85 }, min: -10, max: 10, step: 0.1 },
  //   size: { value: { x: 2.8, y: 0, z: 10 }, min: -10, max: 10, step: 0.1 },
  // });

  const top = useRef();
  const isActive = useRef(false);
  const startTime = useRef(0);

  const setRotation = useFace((state) => state.setRotation);

  const euler = useMemo(() => {
    return new THREE.Euler(0, 0, 0, 'ZYX');
  });

  const quaternion = useMemo(() => {
    return new THREE.Quaternion();
  });

  /*
   * hooks
   */

  useFrame((state, delta) => {
    const time = state.clock.elapsedTime;

    if (!isActive.current && useFace.getState().isMoving) {
      isActive.current = true;
      startTime.current = time;
    }

    const rotation = -0.2 + Math.cos((time - startTime.current) * 2.2) * 0.2;

    // if (isActive.current && !useFace.getState().isMoving) {
    //   if (Math.abs(rotation) < 0.01) {
    //     isActive.current = false;
    //   }
    // }

    // if (isActive.current && !useFace.getState().isCancelled) {
    //   euler.set(rotation, 0, 0);
    //   quaternion.setFromEuler(euler);
    //   top.current.setNextKinematicRotation(quaternion);

    //   setRotation(rotation);
    // }

    if (isActive.current && useFace.getState().isMoving) {
      euler.set(rotation, 0, 0);
      quaternion.setFromEuler(euler);
      top.current.setNextKinematicRotation(quaternion);

      setRotation(rotation);
    }
  });

  /*
   * visuals
   */

  return (
    <>
      {/* HEAD  */}
      <RigidBody ref={top} type="kinematicPosition" position={[0, 1.15, -2]}>
        {/* FACE  */}
        <MeshCollider type="hull">
          <mesh position={[0, 1, 0.5]}>
            <cylinderGeometry args={[1.1, 0.8, 2, 16]} />
            <MeshDiscardMaterial />
          </mesh>
        </MeshCollider>

        {/* NOSE  */}
        <CuboidCollider args={[0.18, 0.35, 0.24]} position={[0, 0.5, 1.2]} />

        {/* JAW RIGHT */}
        <CuboidCollider args={[0.08, 0.22, 0.8]} position={[-0.35, -0.22, 0.3]} />

        {/* JAW LEFT */}
        <CuboidCollider args={[0.08, 0.22, 0.8]} position={[0.35, -0.22, 0.3]} />
      </RigidBody>

      {/* BOTTOM BODY */}
      {/* <RigidBody type="fixed" colliders="hull" position={[0, -1.75, -2.2]} scale={[0.9, 0.4, 0.49]}>
        <mesh>
          <capsuleGeometry args={[2.8, 5, 10, 20]} />
          <MeshDiscardMaterial />
        </mesh>
      </RigidBody> */}

      {/* NECK */}
      <RigidBody type="fixed" colliders="hull" position={[0, 0.35, -1.85]}>
        <mesh>
          <cylinderGeometry args={[0.6, 0.6, 2.0, 16]} />
          <MeshDiscardMaterial />
        </mesh>
      </RigidBody>

      {/* MOUTH BOTTOM */}
      <RigidBody type="fixed" position={[0, 0.875, -1.3]}>
        <CuboidCollider args={[0.24, 0.24, 0.5]} />
      </RigidBody>

      {/* MOUTH INNER */}
      <RigidBody type="fixed" colliders="hull" position={[0, 1.1, -1.12]} name="mouth">
        <mesh>
          <boxGeometry args={[0.46, 0.06, 0.6]} />
          <MeshDiscardMaterial />
          {/* <meshBasicMaterial color="red" /> */}
        </mesh>
      </RigidBody>
    </>
  );
}
