import { button, useControls } from 'leva';
import { useSection } from '../../stores/useSection';
import { Sockel } from './section/Sockel';
import { Game } from './game/Game';
import { Intro } from './intro/Intro';
import { Divider } from './section/Divider';
import { useEffect, useRef } from 'react';
import { Coin } from './coin/Coin';
import { Message } from './message/Message';
import { useGLTF } from '@react-three/drei';

import * as THREE from 'three';
import { RigidBody } from '@react-three/rapier';
import { Model } from './Model';
import { Talk } from './talk/Talk';
import { Floor } from './section/Floor';
import { Dust } from '../components/Dust/Dust';
import { Sparks } from '../components/Sparks/Sparks';
import { Curtains } from '../components/Curtains/Curtains';

export function Section() {
  /*
   * properties
   */

  // const model = useGLTF('gltf/curious_great_curio.gltf');

  const setSection = useSection((state) => state.setSection);

  // const head = useRef();

  /*
   * hooks
   */

  const goto = (section) => {
    setSection(section);
  };

  useControls('set section', {
    Talk: button(() => goto('talk')),
    Coin: button(() => goto('coin')),
    Game: button(() => goto('game')),
    Message: button(() => goto('message')),
  });

  /*
   * visuals
   */

  return (
    <>
      <Floor />
      <Dust />
      <Sparks />

      <group scale={8.5} position={[-0.06, -12, 0]}>
        <Model />
      </group>

      <Talk />
      <Coin />
      <Game />
      <Message />
    </>
  );
}
