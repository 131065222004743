import React, { useEffect, useRef, useState } from 'react';
import { useGLTF, useKTX2, useTexture } from '@react-three/drei';
import { editable as e } from '@theatre/r3f';
import { useFrame, useThree } from '@react-three/fiber';
import { useHandle } from '../../stores/useHandle';
import { useRamp } from '../../stores/useRamp';
import { useFace } from '../../stores/useFace';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader';
import { KTX2Loader } from 'three/examples/jsm/loaders/KTX2Loader';
import { RigidBody } from '@react-three/rapier';
import gsap from 'gsap';

export function Model(props) {
  /*
   * properties
   */

  const isTalking = useFace((state) => state.isTalking);

  const alphaMap = useTexture('/gltf/feather_alphaMap-min.jpg');
  // const alphaMap = useKTX2('/gltf/alpha_feather.ktx2');

  const { gl } = useThree();
  const { nodes, materials } = useGLTF('/gltf/curio.gltf', false, false, (loader) => {
    const dracoLoader = new DRACOLoader();
    dracoLoader.setDecoderPath('loader/draco/gltf/');
    loader.setDRACOLoader(dracoLoader);

    const ktx2Loader = new KTX2Loader().setTranscoderPath('loader/basis/');
    ktx2Loader.detectSupport(gl);
    loader.setKTX2Loader(ktx2Loader);
  });

  const eyeMaterial = useRef();
  const panelMaterial = useRef();
  const [theatreObject, setTheatreObject] = useState(null);

  const leftHandle = useRef();
  const rightHandle = useRef();
  const ramp = useRef();
  const face = useRef();

  /*
   * hooks
   */

  useEffect(() => {
    for (const key in materials) {
      const material = materials[key];
      material.envMapIntensity = 0.25;

      // console.log(key);

      if (key === 'Eye_red') {
        // console.log('Eye_red found');
        eyeMaterial.current = material;
      } else if (key === 'back_panel_light') {
        // material.emissiveIntensity = 0;
        panelMaterial.current = material;
      } else if (key === '0feathers') {
        // console.log('feathers found');
        material.alphaMap = alphaMap;
        material.transparent = true;
        material.depthWrite = false;
      }
    }
  }, [materials]);

  useEffect(() => {
    if (ramp.current) {
      ramp.current.rotation.reorder('ZYX');
    }
  }, [ramp.current]);

  useEffect(
    () => {
      // if `theatreObject` is `null`, we don't need to do anything
      if (!theatreObject) return;

      const unsubscribe = theatreObject.onValuesChange((data) => {
        // Apply the new offset to our THREE.js object
        eyeMaterial.current.emissive.set(data.eye, 0, 0);
        panelMaterial.current.emissiveIntensity = data.panelLight;
      });
      // unsubscribe from the listener when the component unmounts
      return unsubscribe;
    },
    // We only want to run this `useEffect()` when `theatreObject` changes
    [theatreObject]
  );

  useFrame(() => {
    // set handle
    leftHandle.current.rotation.z = useHandle.getState().leftRotation;
    rightHandle.current.rotation.z = useHandle.getState().rightRotation;

    // set ramp
    ramp.current.rotation.x = useRamp.getState().rotationX;
    ramp.current.rotation.y = useRamp.getState().rotationY - 0.05;

    // set face
    if (useFace.getState().isMoving) {
      face.current.rotation.x = useFace.getState().rotation;
    }
  });

  useEffect(() => {
    // console.log('***** ' + isTalking);

    if (isTalking) {
      console.log('Model :: start talking');
      const rot = face.current.rotation;
      const ease = 'sine.inOut';
      const duration = .8;

      var tl = gsap.timeline();
      tl.to(rot, { x: -0.2, duration, ease });
      for (let i = 0; i < 4; i++) {
        tl.to(rot, { x: -0.1, duration, ease });
        tl.to(rot, { x: -0.2, duration, ease });
      }
      tl.to(rot, { x: 0, duration, ease });
    }
  }, [isTalking]);

  /*
   * visual
   */

  return (
    <e.group
      {...props}
      dispose={null}
      theatreKey="model"
      additionalProps={{
        eye: 0,
        panelLight: 0,
      }}
      objRef={setTheatreObject}
    >
      {/* <RigidBody type="fixed" colliders="trimesh"> */}
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Zoltar_mouth.geometry}
        material={materials.mouth}
        position={[0.007, 1.433, -0.192]}
      />
      {/* </RigidBody> */}
      <RigidBody type="fixed" colliders="trimesh">
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Zoltar_body.geometry}
          material={materials.Zoltar_body}
          position={[0.007, 1.353, -0.249]}
        />
      </RigidBody>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.red_panels.geometry}
        material={materials.red_panels}
        position={[0.259, 1.672, 0.105]}
      />
      <mesh geometry={nodes.bottom_cabin.geometry} material={materials.bottom_cabin} position={[0.01, 0.457, 0.202]} />
      <mesh
        receiveShadow
        geometry={nodes.Middle_cabin.geometry}
        material={materials.Middle_cabin}
        position={[0.259, 1.672, 0.105]}
      />
      <mesh
        receiveShadow
        geometry={nodes.details_cabin.geometry}
        material={materials.details_handles}
        position={[-0.284, 1.006, 0.305]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.top_cabin.geometry}
        material={materials.top_cabin}
        position={[0.259, 1.672, 0.105]}
      />
      <mesh
        geometry={nodes.Roof.geometry}
        material={materials.Roof}
        position={[0.259, 1.672, 0.105]}
        envMapIntensity={100}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.back_panel_light.geometry}
        material={materials.back_panel_light}
        position={[0.259, 1.672, 0.105]}
      />
      <mesh
        ref={rightHandle}
        castShadow
        receiveShadow
        geometry={nodes.right_handle.geometry}
        material={materials.details_handles}
        position={[0.294, 1.007, 0.316]}
      />
      <mesh
        ref={leftHandle}
        castShadow
        receiveShadow
        geometry={nodes.left_handle.geometry}
        material={materials.details_handles}
        position={[-0.284, 1.007, 0.316]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.red_button_socle.geometry}
        material={materials.details_handles}
        position={[0.005, 1.007, 0.214]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.red_button.geometry}
        material={materials.details_handles}
        position={[0.005, 1.007, 0.219]}
      />
      <mesh
        geometry={nodes.glass_great_curio_top.geometry}
        material={materials.glass_great_curio}
        position={[0.005, 1.548, -0.144]}
      />
      <mesh
        castShadow
        receiveShadow
        ref={ramp}
        geometry={nodes.ramp_stand.geometry}
        material={materials.details_handles}
        position={[0.328, 1.536, 0.172]}
        rotation={[0, Math.PI / 9, 0]}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.ramp.geometry}
          material={materials.details_handles}
          position={[-0.009, 0.134, -0.101]}
        />
      </mesh>
      {/* <RigidBody type="fixed" colliders="trimesh"> */}
      <mesh
        castShadow
        receiveShadow
        ref={face}
        geometry={nodes.Zoltar_head.geometry}
        material={materials.zoltar_head}
        position={[0.007, 1.548, -0.261]}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Feather02.geometry}
          material={materials['0feathers']}
          position={[0.003, 0.323, 0.157 - 0.5]}
          rotation={[0, 0, -Math.PI]}
          scale={[0.035, -0.035, 0.035]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Feather01.geometry}
          material={materials['0feathers']}
          position={[0.003, 0.323, 0.161 - 0.05]}
          scale={0.035}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Gold_earring.geometry}
          material={materials.Gold_earring}
          position={[-0.081, -0.021, 0.044]}
        />
        <group position={[-0.038, 0.09, 0.129]}>
          <mesh castShadow receiveShadow geometry={nodes.eye_left_1.geometry} material={materials.Eye_red} />
          <mesh castShadow receiveShadow geometry={nodes.eye_left_2.geometry} material={materials.Eye_black} />
        </group>
        <group position={[0.039, 0.09, 0.129]}>
          <mesh castShadow receiveShadow geometry={nodes.eye_right_1.geometry} material={materials.Eye_red} />
          <mesh castShadow receiveShadow geometry={nodes.eye_right_2.geometry} material={materials.Eye_black} />
        </group>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.jewel_top.geometry}
          material={materials.jewels}
          position={[0.003, 0.195, 0.152]}
        />
      </mesh>
      {/* </RigidBody> */}
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.jewel_collar.geometry}
        material={materials.jewels}
        position={[0.007, 1.449, -0.232]}
      />
    </e.group>
  );
}
