import { Environment, OrthographicCamera, SpotLight, useHelper } from '@react-three/drei';
import { useFrame } from '@react-three/fiber';
import { folder, useControls } from 'leva';
import { useEffect, useRef, useState } from 'react';
import { PointLightHelper } from 'three';
import { CameraHelper, DirectionalLightHelper, SpotLightHelper } from 'three';
import { editable as e } from '@theatre/r3f';
import * as THREE from 'three';
// import { useControls } from 'leva';

export function Lighting() {
  /*
   * properties
   */

  const [shadowLightTarget] = useState(() => new THREE.Object3D());
  const pointLight = useRef();
  const shadowLight = useRef();
  const light1 = useRef();
  const light2 = useRef();

  const camera = useRef();

  const config = useControls('lights', {
    pointlight: folder({
      positionPoint: { value: { x: 0, y: -1.14, z: 0.56 }, min: -15, max: 15, step: 0.01, label: 'position' },
      intensityPoint: { value: 11, min: 0, max: 20, step: 0.01, label: 'intensity' },
      colorPoint: { value: '#ffef87', label: 'color' },
    }),
    spotlight: folder({
      penumbra: { value: 0.2, min: 0, max: 20, step: 0.01 },
      distance: { value: 0, min: 0, max: 20, step: 0.01 },
      angle: { value: 0.9, min: 0, max: 2, step: 0.01 },
    }),
    'directional light 1': folder({
      positionLight1: { value: { x: -1.67, y: 6.36, z: -2.3 }, min: -7, max: 7, step: 0.01, label: 'position' },
      positionLight1Target: { value: { x: 0, y: -2.78, z: 0 }, min: -15, max: 15, step: 0.01, label: 'target' },
      intensityLight1: { value: 40, min: 0, max: 40, step: 0.01, label: 'intensity' },
      colorLight1: { value: '#ffd1ba', label: 'color' },
    }),
    'directional light 2': folder({
      positionLight2: { value: { x: 2.24, y: 5.95, z: -2 }, min: -7, max: 7, step: 0.01, label: 'position' },
      positionLight2Target: { value: { x: -0.57, y: -3, z: 0 }, min: -15, max: 15, step: 0.01, label: 'target' },
      intensityLight2: { value: 40, min: 0, max: 40, step: 0.01, label: 'intensity' },
      colorLight2: { value: '#ffe3ba', label: 'color' },
    }),
    'shadow directional light': folder({
      positionShadow: { value: { x: -4.5, y: -1.3, z: -0.2 }, min: -15, max: 15, step: 0.01, label: 'position' },
      positionShadowTarget: { value: { x: 0, y: -0.95, z: -0.75 }, min: -15, max: 15, step: 0.01, label: 'target' },
      intensityShadow: { value: 5, min: 0, max: 20, step: 0.01, label: 'intensity' },
      colorShadow: { value: '#75bcff', label: 'color' },
    }),

    'shadow camera': folder({
      left: { value: -4, min: -10, max: 10, step: 0.01 },
      right: { value: 4, min: -10, max: 10, step: 0.01 },
      top: { value: 7, min: -10, max: 10, step: 0.01 },
      bottom: { value: -5, min: -10, max: 10, step: 0.01 },
      far: { value: 9, min: 0, max: 50, step: 0.01 },
    }),
  });

  // useHelper(light1, DirectionalLightHelper);
  // useHelper(light2, DirectionalLightHelper);
  // useHelper(shadowLight, DirectionalLightHelper);
  // useHelper(camera, CameraHelper);
  // useHelper(pointLight, PointLightHelper);

  /*
   * hooks
   */

  /*
   * visuals
   */

  return (
    <>
      <e.pointLight
        theatreKey="pointlight"
        ref={pointLight}
        position={[config.positionPoint.x, config.positionPoint.y, config.positionPoint.z]}
        // intensity={config.intensityPoint}
        color={config.colorPoint}
      />

      <directionalLight
        // castShadow
        ref={shadowLight}
        position={[config.positionShadow.x, config.positionShadow.y, config.positionShadow.z]}
        // target-position={[config.positionShadowTarget.x, config.positionShadowTarget.y, config.positionShadowTarget.z]}
        intensity={config.intensityShadow}
        color={config.colorShadow}
        target={shadowLightTarget}
        shadow-bias={-0.1}
      >
        <orthographicCamera
          attach="shadow-camera"
          ref={camera}
          near={0.1}
          far={config.far}
          top={config.top}
          bottom={config.bottom}
          left={config.left}
          right={config.right}
        />
      </directionalLight>

      <primitive
        object={shadowLightTarget}
        position={[config.positionShadowTarget.x, config.positionShadowTarget.y, config.positionShadowTarget.z]}
      />

      {shadowLight && shadowLight.current && <primitive object={shadowLight.current.target} />}

      <e.spotLight
        theatreKey="spotLight1"
        castShadow
        ref={light1}
        position={[config.positionLight1.x, config.positionLight1.y, config.positionLight1.z]}
        target-position={[config.positionLight1Target.x, config.positionLight1Target.y, config.positionLight1Target.z]}
        // intensity={config.intensityLight1}
        color={config.colorLight1}
        distance={config.distance}
        penumbra={config.penumbra}
        angle={config.angle}
        shadow-bias={-0.01}
        shadow-mapSize-height={1024}
        shadow-mapSize-width={1024}
        // attenuation={config.attenuation}
        // anglePower={config.anglePower}
      />

      {light1 && light1.current && <primitive object={light1.current.target} />}

      <e.spotLight
        theatreKey="spotLight2"
        castShadow
        ref={light2}
        position={[config.positionLight2.x, config.positionLight2.y, config.positionLight2.z]}
        target-position={[config.positionLight2Target.x, config.positionLight2Target.y, config.positionLight2Target.z]}
        // intensity={config.intensityLight2}
        color={config.colorLight2}
        distance={config.distance}
        penumbra={config.penumbra}
        angle={config.angle}
        shadow-bias={-0.01}
        shadow-mapSize-height={1024}
        shadow-mapSize-width={1024}

        // attenuation={config.attenuation}
        // anglePower={config.anglePower}
      />

      {light2 && light2.current && <primitive object={light2.current.target} />}

      <Environment
        background={false}
        path="./environmentMaps/3/"
        files={['px.jpg', 'nx.jpg', 'py.jpg', 'ny.jpg', 'pz.jpg', 'nz.jpg']}
      />

      {/* <Environment
        background={false}
        path="./environmentMaps/light/"
        files={['px.png', 'nx.png', 'py.png', 'ny.png', 'pz.png', 'nz.png']}
      /> */}
    </>
  );
}
