import { create } from 'zustand';

export const useFace = create((set, get) => ({
  rotation: 0,
  isMoving: false,
  isTalking: false,

  setRotation: (rotation) => set({ rotation }),

  startMoving: () => set({ isMoving: true }),
  stopMoving: () => set({ isMoving: false }),

  startTalking: () => set({ isTalking: true }),
  stopTalking: () => set({ isTalking: false }),
}));
