import { useEffect, useRef, useState } from 'react';
import { useSection } from '../../../stores/useSection';
import { useFace } from '../../../stores/useFace';
import { editable as e, SheetProvider } from '@theatre/r3f';
import { useSounds } from '../../../stores/useSounds';

export function Talk() {
  /*
   * properties
   */

  const startTalking = useFace((state) => state.startTalking);
  const stopTalking = useFace((state) => state.stopTalking);

  const playSound = useSounds((state) => state.playSound);

  // const section = useSection((state) => state.section);
  const setSection = useSection((state) => state.setSection);

  // const [shown, setShown] = useState(false);

  const [theatreObject, setTheatreObject] = useState(null);

  /*
   * hooks
   */

  useEffect(
    () => {
      // if `theatreObject` is `null`, we don't need to do anything
      if (!theatreObject) return;

      const unsubscribe = theatreObject.onValuesChange((data) => {
        // Apply the new offset to our THREE.js object
        // console.log('Talk :: action:  ' + data.action);

        if (data.action === 'startTalking') {
          startTalking();
          playSound('speech');
        } else if (data.action === 'stopTalking') {
          stopTalking();
        } else if (data.action === 'showLight') {
          playSound('lights');
        } else if (data.action === 'startCoin') {
          setSection('coin');
        }
      });
      // unsubscribe from the listener when the component unmounts
      return unsubscribe;
    },
    // We only want to run this `useEffect()` when `theatreObject` changes
    [theatreObject]
  );

  /*
   * visuals
   */

  return (
    <>
      <e.group
        theatreKey="talk"
        additionalProps={{
          action: '',
        }}
        objRef={setTheatreObject}
      ></e.group>
    </>
  );
}
