import style from './Curtains.module.css';
import { useEffect, useRef, useState } from 'react';
import gsap from 'gsap';

export function Curtains({ show }) {
  /*
   * properties
   */

  const leftContainer = useRef();
  const leftCurtain = useRef();

  const rightContainer = useRef();
  const rightCurtain = useRef();

  /*
   * hooks
   */

  useEffect(() => {
    const delay = 1;
    gsap.to(leftContainer.current, { delay, rotation: 10, duration: 1.3, ease: 'power2.in' });
    gsap.to(rightContainer.current, { delay, rotation: -10, duration: 1.3, ease: 'power2.in' });

    gsap.to(leftCurtain.current, { delay, scaleX: 0, scaleY: 1.3, duration: 2, ease: 'power2.inOut' });
    gsap.to(rightCurtain.current, { delay, scaleX: 0, scaleY: 1.3, duration: 2, ease: 'power2.inOut' });
  }, [show]);

  /*
   * visuals
   */

  return (
    <>
      <div ref={leftContainer} className={style.curtainContainer}>
        <div ref={leftCurtain} className={style.curtainLeft}>
          <img src="/images/curtain_left-min.jpg" className={style.image} alt="left curtain" />
        </div>
      </div>
      <div ref={rightContainer} className={style.curtainContainer}>
        <div ref={rightCurtain} className={style.curtainRight}>
          <img src="/images/curtain_right-min.jpg" className={style.image} alt="left curtain" />
        </div>
      </div>
    </>
  );
}
