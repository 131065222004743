import { shaderMaterial, useTexture } from '@react-three/drei';
import { useFrame, extend } from '@react-three/fiber';
import { useEffect, useMemo, useRef, useState } from 'react';
import vertexShader from './shaders/vertex.glsl?raw';
import fragmentShader from './shaders/fragment.glsl?raw';
import * as THREE from 'three';
import { useSize } from '../../../stores/useSize';

const amount = 350;
const width = 30;
const height = 16;
const depth = 25;

const Dustmaterial = shaderMaterial(
  {
    uTime: 0,
    uDpr: 1,
    uColor: new THREE.Color('#679abb'),
    uTexture: null,
    uResolutionFactor: 150,
  },
  vertexShader,
  fragmentShader
);

extend({ Dustmaterial });

export function Dust({ color }) {
  /*
   * properties
   */
  const dustMaterial = useRef();
  const mesh = useRef();
  const dpr = useSize((state) => state.dpr);

  const texture = useTexture('/textures/blurredPoint-min.png');

  const points = useMemo(() => {
    const p = new Array(amount * 3);
    for (let i = 0; i < amount; i++) {
      p[i * 3 + 0] = -width * 0.5 + Math.random() * width;
      p[i * 3 + 1] = -height * 0.5 + Math.random() * height;
      p[i * 3 + 2] = -depth * 0.5 + Math.random() * depth;
    }

    return new Float32Array(p);
  }, [amount]);

  const rnd = useMemo(() => {
    const s = new Array(amount);
    for (let i = 0; i < amount; i++) {
      s[i] = 10 * Math.random();
    }

    return new Float32Array(s);
  }, [amount]);

  const sizes = useMemo(() => {
    const s = new Array(amount);
    for (let i = 0; i < amount; i++) {
      s[i] = 0.7 + Math.random() * 1.4;
    }

    return new Float32Array(s);
  }, [amount]);

  /*
   * hooks
   */

  useEffect(() => {
    window.addEventListener('resize', resizeHandler);
    resizeHandler();

    return () => {
      window.removeEventListener('resize', resizeHandler);
    };
  }, []);

  useEffect(() => {
    // console.log('*** ' + dpr + ' ***');
    dustMaterial.current.uniforms.uDpr.value = dpr;
  }, [dpr]);

  const resizeHandler = () => {
    dustMaterial.current.uniforms.uResolutionFactor.value = Math.min(window.innerWidth, window.innerHeight) * 0.15;
  };

  useFrame((state) => {
    const time = state.clock.elapsedTime;
    dustMaterial.current.uniforms.uTime.value = time * 0.6;
  });

  /*
   * visuals
   */

  return (
    <>
      <group position={[0, -2, 0]}>
        {/* <mesh>
          <boxGeometry args={[width, height, depth]} />
          <meshBasicMaterial color="cyan" wireframe />
        </mesh> */}
        <points ref={mesh}>
          <bufferGeometry>
            <bufferAttribute attach={'attributes-position'} args={[points, 3, false]} />
            <bufferAttribute attach={'attributes-aRnd'} args={[rnd, 1, false]} />
            <bufferAttribute attach={'attributes-aSize'} args={[sizes, 1, false]} />
          </bufferGeometry>
          <dustmaterial ref={dustMaterial} uColor={color} uTexture={texture} transparent={true} depthWrite={false} />
        </points>
      </group>
    </>
  );
}
