import { create } from 'zustand';

export const useCoins = create((set, get) => ({
  coins: 0,
  physicsEnabled: false,

  addCoin: () => set({ coins: (get().coins += 1) }),

  enablePhysics: () => set({ physicsEnabled: true }),
  disablePhysics: () => set({ physicsEnabled: false }),
}));
