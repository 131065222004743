import { Coin } from './Coin';
import { Ramp } from './Ramp';
import { Handle } from './Handle';
import { Walls } from './Walls';
import { Button } from './Button';
import { Statue } from './Statue';
import { useSection } from '../../../stores/useSection';
import { useEffect, useRef, useState } from 'react';
import { useFace } from '../../../stores/useFace';
import { useCoins } from '../../../stores/useCoins';
import { useSounds } from '../../../stores/useSounds';

export function Game() {
  /*
   * properties
   */

  const playSound = useSounds((state) => state.playSound);
  const startMoving = useFace((state) => state.startMoving);
  const stopMoving = useFace((state) => state.stopMoving);
  const section = useSection((state) => state.section);
  const setSection = useSection((state) => state.setSection);
  const coins = useCoins((state) => state.coins);

  const timeoutId = useRef();
  const [isEnabled, setIsEnabled] = useState(false);
  const [isActive, setIsActive] = useState(false);

  /*
   * hooks
   */

  useEffect(() => {
    console.log('Game :: on change section');

    if (section === 'game') {
      if (!isActive) {
        console.log('Game :: show');
        setIsActive(true);
        startMoving();
        playSound('breath');
      }

      console.log('Game :: enable');
      setIsEnabled(true);
    }
  }, [section]);

  useEffect(() => {
    if (coins > 0) {
      console.log('Game :: launch coin');
      setIsEnabled(false);

      timeoutId.current = setTimeout(restart, 3000);
    }
  }, [coins]);

  const restart = () => {
    console.log('Game :: restart');
    setSection('coin');
  };

  // const onContactForce = (payload) => {
  const endGame = (payload) => {
    console.log('*** Game :: endGame');
    // // console.log(payload);
    if (isActive) {
      console.log('Game :: game over');
      playSound('gameover');
      clearTimeout(timeoutId.current);
      setIsActive(false);
      stopMoving();
      setTimeout(hide, 2000);
    }
  };

  const hide = () => {
    console.log('Game :: hide');
    setSection('message');
  };

  /*
   * visuals
   */

  return (
    <>
      <Walls />
      <Statue />

      <Button isEnabled={isEnabled} />
      <Handle left={true} isActive={isActive} />
      <Handle left={false} isActive={isActive} />

      <Ramp />

      {[...Array(100)].map((value, index) => (
        <Coin key={index} index={index} endGame={endGame} />
      ))}
    </>
  );
}
