import style from './MainButton.module.css';
import { useEffect, useMemo, useRef, useState } from 'react';
import gsap from 'gsap';

// const canvas = document.getElementById('canvas');
// const ctx = canvas.getContext('2d');
// canvas.width = 270;
// canvas.height = 270;

// Circle properties
const circleRadius = 50; // Radius of the circle in pixels
// const circleCenterX = canvas.width / 2;
// const circleCenterY = canvas.height / 2;

// Animation properties
let rotation = 0;
let currentRotationSpeed = 0.001;
let targetRotationSpeed = 0.001;
const defaultRotationSpeed = 0.001;
const enhancedRotationSpeed = 0.005;

// Scaling properties
let scale = 1;
let targetScale = 1.2;
let scalingSpeed = 0.02; // Speed of scale transition

// Duration properties for triangle animation
let currentDuration = 1000;
let targetDuration = 1000;
const defaultDuration = 1000;
const enhancedDuration = 500;

// Triangle properties
const numTriangles = 19; // Number of triangles
const minHeight = 60;
const maxHeight = 110;
const minBaseWidth = 20;
const maxBaseWidth = 40;
const baseWidth = Math.random() * (maxBaseWidth - minBaseWidth) + minBaseWidth + 20; // Base width of the triangles

// Initializing triangles array with starting heights, target heights, and start times
let triangles = [];
for (let i = 0; i < numTriangles; i++) {
  let startHeight = getRandomHeight(minHeight, maxHeight);
  triangles.push({
    startHeight: startHeight,
    height: startHeight,
    targetHeight: getRandomHeight(minHeight, maxHeight),
    startTime: performance.now(),
    duration: defaultDuration,
  });
}

// Utility functions
function getRandomHeight(min, max) {
  return Math.random() * (max - min) + min;
}

function easingFunction(x) {
  return -(Math.cos(Math.PI * x) - 1) / 2;
}

function updateScale() {
  if (Math.abs(scale - targetScale) > 0.005) {
    scale += (targetScale - scale) * scalingSpeed;
  }
}

function lerp(start, end, factor) {
  return start + (end - start) * factor;
}

function updateEasingProperties() {
  currentRotationSpeed = lerp(currentRotationSpeed, targetRotationSpeed, 0.1);
  currentDuration = lerp(currentDuration, targetDuration, 0.1);
}

function updateTriangles(now) {
  triangles.forEach((triangle) => {
    const elapsedTime = now - triangle.startTime;
    const progress = elapsedTime / triangle.duration;
    if (progress < 1) {
      triangle.height =
        triangle.startHeight + (triangle.targetHeight - triangle.startHeight) * easingFunction(progress);
    } else {
      triangle.startHeight = triangle.targetHeight;
      triangle.targetHeight = getRandomHeight(minHeight, maxHeight);
      triangle.startTime = now;
      triangle.duration = triangle.isEnhanced ? enhancedDuration : defaultDuration;
    }
  });
}

// Drawing function
function drawFrame(canvas, ctx, circle) {
  // const circleCenterX = canvas.width / 2;
  // const circleCenterY = canvas.height / 2;

  const circleCenter = 270 / 2;

  // const circleCenterX = 270 / 2;
  // const circleCenterY = 270 / 2;

  ctx.clearRect(0, 0, canvas.width, canvas.height); // Clear canvas

  // Update rotation
  rotation += currentRotationSpeed;

  // Update rotation for DOM element
  let rotationDegrees = (rotation * (300 / Math.PI)) % 360;
  circle.style.transform = `rotate(${rotationDegrees}deg)`;

  // Draw and rotate the burst
  ctx.save();
  ctx.translate(circleCenter, circleCenter);
  ctx.rotate(rotation);
  ctx.translate(-circleCenter, -circleCenter);

  // Draw central circle
  ctx.beginPath();
  ctx.arc(circleCenter, circleCenter, circleRadius + 3, 0, 2 * Math.PI);
  ctx.fillStyle = '#4ad894';
  ctx.fill();

  // Draw triangles
  triangles.forEach((triangle, i) => {
    const angle = ((2 * Math.PI) / numTriangles) * i;
    const baseCenterX = circleCenter + circleRadius * Math.cos(angle);
    const baseCenterY = circleCenter + circleRadius * Math.sin(angle);
    const apexX = baseCenterX + triangle.height * Math.cos(angle);
    const apexY = baseCenterY + triangle.height * Math.sin(angle);

    ctx.beginPath();
    ctx.moveTo(
      baseCenterX + (baseWidth / 2) * Math.cos(angle + Math.PI / 2),
      baseCenterY + (baseWidth / 2) * Math.sin(angle + Math.PI / 2)
    );
    ctx.quadraticCurveTo(baseCenterX, baseCenterY, apexX, apexY);
    ctx.quadraticCurveTo(
      baseCenterX,
      baseCenterY,
      baseCenterX - (baseWidth / 2) * Math.cos(angle + Math.PI / 2),
      baseCenterY - (baseWidth / 2) * Math.sin(angle + Math.PI / 2)
    );
    ctx.closePath();
    ctx.fillStyle = '#4ad894';
    ctx.fill();
  });

  ctx.restore();
}

// Animation loop
function animate(now, canvas, ctx, circle) {
  updateScale();
  updateEasingProperties();
  updateTriangles(now);
  drawFrame(canvas, ctx, circle);
  // requestAnimationFrame(animate);
}

// requestAnimationFrame(animate);

// Event listeners for mouse hover
// canvas.addEventListener('mouseenter', () => {
//   targetRotationSpeed = enhancedRotationSpeed;
//   targetDuration = enhancedDuration;
//   targetScale = 1.4;
// });

// canvas.addEventListener('mouseleave', () => {
//   targetRotationSpeed = defaultRotationSpeed;
//   targetDuration = defaultDuration;
//   targetScale = 1;
// });

export function MainButton({ shown, clickHandler, hoverHandler, leaveHandler }) {
  /*
   * properties
   */

  const button = useRef();
  const canvas = useRef();
  const ctx = useRef();
  const inner = useRef();
  const circle = useRef();
  // const start = useRef();

  /*
   * hooks
   */

  useEffect(() => {
    setCircle();
    setCanvas();

    window.addEventListener('resize', resizeHandler);
    resizeHandler();

    return () => {
      window.removeEventListener('resize', resizeHandler);
    };
  }, []);

  const setCircle = () => {
    const letters = circle.current.querySelectorAll('span');
    letters.forEach((el, index) => {
      el.style.transform = `rotate(calc(360 / ${letters.length} * ${index}deg)`;
    });
  };

  const setCanvas = () => {
    ctx.current = canvas.current.getContext('2d');
    const size = 270;

    // 1. Multiply the canvas's width and height by the devicePixelRatio
    const ratio = window.devicePixelRatio || 1;
    canvas.current.width = size * ratio;
    canvas.current.height = size * ratio;

    // 2. Force it to display at the original (logical) size with CSS or style attributes
    canvas.current.style.width = size + 'px';
    canvas.current.style.height = size + 'px';

    // 3. Scale the context so you can draw on it without considering the ratio.
    ctx.current.scale(ratio, ratio);
  };

  const resizeHandler = () => {
    const scale = Math.min(window.innerWidth, window.innerHeight) / 600;
    button.current.style.transform = `scale(${scale})`;
  };

  useEffect(() => {
    if (shown) {
      gsap.set(canvas.current, { scale: 0.8 });
      gsap.to(canvas.current, { delay: 0.7, duration: 1, opacity: 1, scale: 1, ease: 'back.out' });

      gsap.set(circle.current, { scale: 0.8 });
      gsap.to(circle.current, { delay: 1, opacity: 1, scale: 1, ease: 'sine.out' });
      loop();
    }
  }, [shown]);

  const loop = (now) => {
    if (canvas.current) {
      animate(now, canvas.current, ctx.current, circle.current);

      requestAnimationFrame(loop);
    }
  };

  const overHandler = (now) => {
    hoverHandler();

    gsap.killTweensOf(inner.current);
    gsap.to(inner.current, { duration: 1.2, scale: 1.15, ease: 'elastic.out' });

    targetRotationSpeed = enhancedRotationSpeed;
    targetDuration = enhancedDuration;
    targetScale = 1.4;
  };

  const outHandler = () => {
    leaveHandler();

    gsap.killTweensOf(inner.current);
    gsap.to(inner.current, { duration: 0.3, scale: 1, ease: 'back.inOut' });

    targetRotationSpeed = defaultRotationSpeed;
    targetDuration = defaultDuration;
    targetScale = 1;
  };

  /*
   * visuals
   */

  return (
    <>
      <button
        ref={button}
        aria-label="start"
        disabled={!shown}
        onClick={clickHandler}
        onMouseEnter={overHandler}
        onMouseLeave={outHandler}
        className={style.button}
      >
        <div ref={inner} className={style.inner}>
          <canvas ref={canvas} className={style.canvas} width="270" height="270" />
          <div className={style.burstHitArea}>
            <div ref={circle} className={style.circle}>
              {'CLICK TO PROCEED • REVEAL YOUR FATE • '.split('').map((value, index) => (
                <span key={index}>{value}</span>
              ))}
            </div>
          </div>
        </div>
      </button>
    </>
  );
}
