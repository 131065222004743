import { useTexture } from '@react-three/drei';
import * as THREE from 'three';

export function Floor() {
  /*
   * properties
   */

  const [planksNormal, planksRoughness] = useTexture([
    '/gltf/painted_wood_planks_wall_Normal.png',
    '/gltf/painted_wood_planks_wall_Roughness0.png',
  ]);

  planksNormal.wrapS = planksNormal.wrapT = THREE.RepeatWrapping;
  planksRoughness.wrapS = planksRoughness.wrapT = THREE.RepeatWrapping;

  planksNormal.repeat.set(6, 6);
  planksRoughness.repeat.set(6, 6);

  /*
   * hooks
   */

  /*
   * visuals
   */

  return (
    <>
      <mesh position={[0, -11.7, 0]} rotation={[-1.57, 0, 0]}>
        <planeGeometry args={[100, 100, 10, 10]} />
        <meshStandardMaterial
          map={planksRoughness}
          normalMap={planksNormal}
          //   normalScale={[10, 10]}
          roughnessMap={planksRoughness}
          metalness={0}
          roughness={1}
          envMapIntensity={0.15}
        />
      </mesh>
    </>
  );
}
