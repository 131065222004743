import './style.css';

import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { StrictMode } from 'react';

import { MouseController } from './controllers/MouseController';
import { App3D } from './app3D/App3D';

import { MainLoader } from './app2D/MainLoader';
import { App } from './App';

const root = ReactDOM.createRoot(document.querySelector('#root'));

root.render(
  <StrictMode>
    <App />
  </StrictMode>
);
