import { create } from 'zustand';

export const useRamp = create((set, get) => ({
  pctX: 0,
  pctY: 0,
  // rotationX: 0, //ideal
  // rotationY: 0.85, //ideal
  rotationX: 0.1,
  rotationY: 0.95,

  addPctX: (extra) => set({ pctX: Math.max(-1, Math.min(1, get().pctX + extra)) }),
  addPctY: (extra) => set({ pctY: Math.max(-1, Math.min(1, get().pctY + extra)) }),

  setRotationX: () => set({ rotationX: 0.1 + get().pctX * 0.14 }),
  setRotationY: () => set({ rotationY: 0.95 + get().pctY * 0.5 }),
}));
