import { CuboidCollider, CylinderCollider, RigidBody } from '@react-three/rapier';
import { useMemo, useRef, useState } from 'react';
import { useCoins } from '../../../stores/useCoins';
import { Coin } from './Coin';
import * as THREE from 'three';
import { Ramp } from './Ramp';
import { Handle } from './Handle';
import { useControls } from 'leva';

export function Walls() {
  /*
   * properties
   */

  // const config = useControls('walls', {
  //   position: { value: { x: 0, y: -3.02, z: 0.85 }, min: -10, max: 10, step: 0.1 },
  //   size: { value: { x: 4, y: 0.25, z: 0.6 }, min: -10, max: 10, step: 0.1 },
  // });

  /*
   * hooks
   */

  /*
   * visuals
   */

  return (
    <>
      <RigidBody type="fixed">
        {/* FLOOR BACK */}
        <CuboidCollider args={[4, 0.25, 3]} position={[0, -2.67, -2.4]} />
        {/* FLOOR FRONT */}
        <CuboidCollider args={[4, 0.25, 0.6]} position={[0, -3, 0.89]} rotation={[0.7, 0, 0]} />
        {/* FLOOR SIGN */}
        <CuboidCollider args={[1.75, 0.25, 0.45]} position={[0, -2.94, 0.94]} rotation={[0.7, 0, 0]} />

        {/* FRONT */}
        <CuboidCollider args={[4, 2.8, 0.25]} position={[0, -0.4, 1.6]} />
        {/* BACK */}
        <CuboidCollider args={[4, 2.8, 0.25]} position={[0, -0.4, -3.1]} />
        {/* LEFT */}
        <CuboidCollider args={[0.25, 2.8, 4]} position={[-3, -0.4, -1.5]} />
        {/* RIGHT */}
        <CuboidCollider args={[0.25, 2.8, 4]} position={[3, -0.4, -1.5]} />
      </RigidBody>

      {/* <CuboidCollider
        args={[config.size.x, config.size.y, config.size.z]}
        position={[config.position.x, config.position.y, config.position.z]}
      /> */}

      {/* GLASS */}
      <mesh position={[0, 1.5, -1.2]}>
        <boxGeometry args={[5.9, 11, 5.9]} />
        <meshStandardMaterial
          side={THREE.DoubleSide}
          // blending={THREE.AdditiveBlending}
          transparent={true}
          opacity={0.1}
          roughness={0.05}
          metalness={0.5}
          color={'#005e5d'}
          envMapIntensity={20}
        />
      </mesh>
    </>
  );
}
