import { CylinderCollider, RigidBody } from '@react-three/rapier';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useCoins } from '../../../stores/useCoins';
import { useFrame } from '@react-three/fiber';
import * as THREE from 'three';
import { useRamp } from '../../../stores/useRamp';
import { useGesture } from '@use-gesture/react';
import { Outlines, useCursor } from '@react-three/drei';
import { useButton } from '../../../stores/useButton';
import { useSounds } from '../../../stores/useSounds';

export function Button({ isEnabled }) {
  /*
   * properties
   */

  const addCoin = useCoins((state) => state.addCoin);
  const setOver = useButton((state) => state.setOver);
  const playSound = useSounds((state) => state.playSound);

  // const isEnabled = useRef(true);
  const [hovered, setHovered] = useState(false);
  useCursor(hovered);

  /*
   * hooks
   */

  const clickHandler = () => {
    // console.log('clickHandler')
    if (isEnabled) {
      addCoin();
      // onLaunch();

      // setTimeout(() => {
      //   // isEnabled.current = true;
      // }, 1500);
    }
    setOver(false);
    playSound('button');
  };

  const overHandler = () => {
    setHovered(true);
    setOver(true);
  };

  const outHandler = () => {
    setHovered(false);
    setOver(false);
  };

  /*
   * visuals
   */

  return (
    <>
      <mesh position={[0, -3.4, 2]} onPointerOver={overHandler} onPointerOut={outHandler} onClick={clickHandler}>
        <boxGeometry args={[0.6, 0.6, 0.3]} />
        <meshStandardMaterial transparent={true} opacity={0} />
        {/* <Outlines thickness={0.05} color="hotpink" visible={hovered} onClick={clickHandler} /> */}
      </mesh>
    </>
  );
}
