import { CylinderCollider, RigidBody } from '@react-three/rapier';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useCoins } from '../../../stores/useCoins';
import { useFrame } from '@react-three/fiber';
import * as THREE from 'three';
import { useRamp } from '../../../stores/useRamp';
import { useGesture } from '@use-gesture/react';
import { Html, Outlines, useCursor } from '@react-three/drei';
import { useHandle } from '../../../stores/useHandle';
import { useSounds } from '../../../stores/useSounds';
// import style from './Handle.module.css';

export function Handle({ left, isActive }) {
  /*
   * properties
   */

  // const paragraph = useRef();

  // const playSound = useSounds((state) => state.playSound);

  const addPctX = useRamp((state) => state.addPctX);
  const addPctY = useRamp((state) => state.addPctY);
  const setRotationX = useRamp((state) => state.setRotationX);
  const setRotationY = useRamp((state) => state.setRotationY);

  const setLeftRotation = useHandle((state) => state.setLeftRotation);
  const setRightRotation = useHandle((state) => state.setRightRotation);

  const mesh = useRef();
  const isDown = useRef(false);

  const [hovered, setHovered] = useState(false);
  useCursor(hovered);

  useGesture(
    {
      onDrag: (state) => {
        // console.log(isDown.current);
        // paragraph.current.innerText += 'onDrag\n';
        if (isDown.current) {
          // console.log('IS DRAGGING');
          // paragraph.current.innerText += state.velocity[0] + '\n';
          // console.log(state);
          const pct1 = state.velocity[0] * state.direction[0];
          const pct2 = state.velocity[1] * state.direction[1];
          const pct = (-pct1 + pct2) * 0.05;
          // console.log(pct);

          if (left) {
            addPctX(-pct);
            setRotationX();
            // mesh.current.rotation.y = -useRamp.getState().pctX;
            setLeftRotation(-useRamp.getState().pctX * 5);
          } else {
            addPctY(pct);
            setRotationY();
            // mesh.current.rotation.y = useRamp.getState().pctY;
            setRightRotation(useRamp.getState().pctY * 5);
          }
        }
      },
    },
    {
      target: window,
    }
  );

  /*
   * hooks
   */

  useEffect(() => {
    window.addEventListener('mouseup', mouseupHandler);
    window.addEventListener('touchend', touchEndHandler);

    return () => {
      window.removeEventListener('mouseup', mouseupHandler);
      window.removeEventListener('touchend', touchEndHandler);
    };
  }, []);

  const downHandler = () => {
    // if (paragraph.current) {
    //   paragraph.current.innerText += 'downHandler\n';
    // }
    // console.log(paragraph.current);
    console.log('downHandler');
    if (isActive) {
      isDown.current = true;
    }
  };

  const mouseupHandler = () => {
    // if (paragraph.current) {
    //   paragraph.current.innerText += 'mouseupHandler\n';
    // }
    cancelHandler();
  };

  const touchEndHandler = () => {
    // if (paragraph.current) {
    //   paragraph.current.innerText += 'touchEndHandler\n';
    // }
    cancelHandler();
  };

  const cancelHandler = () => {
    //   if (paragraph.current) {
    //     paragraph.current.innerText += 'cancelHandler\n';
    //   }
    console.log('cancelHandler');
    isDown.current = false;
  };

  // const upHandler = () => {
  //   // console.log('upHandler');
  //   isDown.current = false;
  // };

  const overHandler = () => {
    setHovered(true);
  };

  const outHandler = () => {
    setHovered(false);
  };

  /*
   * visuals
   */

  return (
    <>
      <mesh
        ref={mesh}
        position={[left ? -2.4 : 2.4, -3.45, 2.6]}
        rotation={[1.57, 0, 0]}
        onPointerDown={downHandler}
        onPointerOver={overHandler}
        onPointerOut={outHandler}
      >
        <cylinderGeometry args={[0.6, 0.6, 1.6, 8]} />
        <meshStandardMaterial transparent={true} opacity={0} />
        {/* <Outlines thickness={0.05} color="hotpink" visible={hovered} /> */}
      </mesh>
      {/* {left && (
        <Html wrapperClass={style.wrapper}>
          <p ref={paragraph} className={style.body}></p>
        </Html>
      )} */}
    </>
  );
}
