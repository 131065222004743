import { Staging } from './core/Staging';
import { CameraManager } from './core/CameraManager';
import { Lighting } from './core/Lighting';
import { PostProcessing } from './core/Postprocessing';
import { Section } from './sections/Section';
import { Canvas } from '@react-three/fiber';
import { OrbitControls, PerformanceMonitor, Preload } from '@react-three/drei';
import { Suspense, useEffect, useRef, useState } from 'react';
import { Physics } from '@react-three/rapier';
import { getProject } from '@theatre/core';
import { SheetProvider } from '@theatre/r3f';
import projectState from '../theatre/state.json';
import { useSection } from '../stores/useSection';
import { Dust } from './components/Dust/Dust';
import { Sparks } from './components/Sparks/Sparks';
import { Curtains } from './components/Curtains/Curtains';
import { useSize } from '../stores/useSize';
import { Perf } from 'r3f-perf';
import { useCoins } from '../stores/useCoins';

export function App3D() {
  /*
   * properties
   */

  // const [pausePhysics, setPausePhysics] = useState(true);

  const physicsEnabled = useCoins((state) => state.physicsEnabled);
  const enablePhysics = useCoins((state) => state.enablePhysics);

  const section = useSection((state) => state.section);

  const dpr = useSize((state) => state.dpr);
  const setDpr = useSize((state) => state.setDpr);
  const [shadows, setShadows] = useState(true);

  // const project = getProject('Curio');
  const project = getProject('Curio', { state: projectState });
  const sheet = project.sheet('Curio');

  /*
   * hooks
   */

  // useEffect(() => {
  //   sheet.project.ready.then(() => setSection('intro'));
  // }, []);

  const performanceChange = (state) => {
    // console.log(state);

    const sum = state.averages.reduce((acc, val) => acc + val, 0);
    const averageFps = state.averages.length ? sum / state.averages.length : 0;
    // console.log(averageFps);

    const deviceDpr = Math.min(2, window.devicePixelRatio);

    if (averageFps < 24) {
      if (dpr === deviceDpr && averageFps > 14) {
        setDpr(deviceDpr * 0.75);
      } else if (dpr > deviceDpr * 0.5) {
        setDpr(deviceDpr * 0.5);
      } else if (shadows) {
        setShadows(false);
      }
    }
  };

  useEffect(() => {
    console.log('section: ', section);

    // if (section === 'intro') {
    //   setTimeout(() => {
    //     sheet.sequence.play({ range: [0, 5] });
    //   }, 500);
    // } else if (section === 'talk') {
    if (section === 'talk') {
      sheet.sequence.play({ range: [0, 16] });
      // sheet.sequence.play({ range: [0, 16] });
    } else if (section === 'coin') {
      sheet.sequence.play({ range: [16, 20] });
    } else if (section === 'message') {
      sheet.sequence.play({ range: [20, 28] });
    }

    if (section === 'game' && !physicsEnabled) {
      enablePhysics();
    }
    // else if (section === 'message' && !pausePhysics) {
    //   setPausePhysics(true);
    // }
  }, [section]);

  return (
    <>
      <Canvas shadows={shadows} flat={false} dpr={dpr}>
        {/* <Perf position="top-left" /> */}
        <PerformanceMonitor ms={250} iterations={5} onChange={performanceChange} bounds={(refreshrate) => [24, 200]} />
        <SheetProvider sheet={sheet}>
          <CameraManager />
          <Staging />
          <Lighting />
          <Physics debug={false} gravity={[0, -12, 0]} paused={!physicsEnabled}>
            <Section />
          </Physics>
        </SheetProvider>
        <Preload all />

        {/* <ambientLight />
        <OrbitControls makeDefault dampingFactor={1} /> */}
      </Canvas>
    </>
  );
}
